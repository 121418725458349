import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'

export const BlogContext = React.createContext({
  title: '',
})

interface PageTemplateProps {
  data: {
    page: {
      path?: string
      title?: string
      seo: any
      flexContent: any
    }
  }
  location?: any
}

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: {
    page: { title = '', flexContent, seo },
  },
  location = {},
}) => (
  <Layout location={location}>
    <SEO seo={seo} />

    <BlogContext.Provider
      value={{
        title,
      }}
    >
      <FlexContentHandler
        prefix="post_Flexcontent"
        fields={flexContent}
        location={location}
        title={title}
      />
    </BlogContext.Provider>
  </Layout>
)

export const pageQuery = graphql`
  query PostById($id: String!) {
    page: wpPost(id: { eq: $id }) {
      ...generalPostFragment
    }
  }
`

export default PageTemplate
